<template>
  <a-card style="background-color: #fff; " class="main-box">
    <div :class="advanced ? 'search' : null" v-if="hasPermission('GOODSAUTARKY_LIST')">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <a-row style="display:flex;flex-wrap:wrap;align-items: center;padding: 10px 0;">
            <!-- <a-form-item label="商品名称"  :labelCol="{ span: 8 }" style="margin-left:10px"
                :wrapperCol="{ span: 14, offset: 1 }">
                <a-input v-model="searchParams.name" placeholder="请输入商品名称" />
              </a-form-item>
              <a-form-item label="供应商ID" :labelCol="{ span: 8 }" style="margin-left:10px"
                :wrapperCol="{ span: 14, offset: 1 }">
                <a-input placeholder="请输入商品ID" v-model="searchParams.supplierId" type="number" />
              </a-form-item> -->


            <a-form-item label="7天无理由" :labelCol="{ span: 8 }" style="margin-left:10px"
              :wrapperCol="{ span: 14, offset: 1 }">
              <a-select v-model="queryData.returnable" style="width: 120px" placeholder="请选择" allowClear option-label-prop="label">
                <a-select-option value="0" label="未知">
                  未知
                </a-select-option>
                <a-select-option value="1" label="支持">
                  支持
                </a-select-option>
                <a-select-option value="2" label="不支持">
                  不支持
                </a-select-option>
              </a-select>
            </a-form-item>

            <div style="display: flex;align-items: center;width:200px;margin: 0 10px">
              <span style="color:rgba(0, 0, 0, 0.85);">优加底价：</span>
              <a-input style="flex:1" v-model="queryData.minPrice" placeholder="最小" />
              <span>-</span>
              <a-input style="flex:1" v-model="queryData.maxPrice" placeholder="最大" />
            </div>
            <div style="display: flex;align-items: center;width:250px;margin: 0 10px">
              <span style="color:rgba(0, 0, 0, 0.85);">市场建议零售价：</span>
              <a-input style="flex:1" v-model="queryData.minAdvicePrice" placeholder="最小" />
              <span>-</span>
              <a-input style="flex:1" v-model="queryData.maxAdvicePrice" placeholder="最大" />
            </div>

            <div style="display: flex;align-items: center;width:200px;margin-right:10px">
              <span style="color:rgba(0, 0, 0, 0.85);">电商售价：</span>
              <a-input style="flex:1" v-model="queryData.minMarketPrice" placeholder="最小" />
              <span>-</span>
              <a-input style="flex:1" v-model="queryData.maxMarketPrice" placeholder="最大" />
            </div>
            <div style="display: flex;width: 460px;align-items: center;">
              <span style="color:rgba(0, 0, 0, 0.85);display:block;width:70px">分类：</span>
              <a-select @change="changeCategory" v-model="menuId" placeholder="菜单" style="width:120px" allowClear
                option-label-prop="label">
                <a-select-option :value="item.id" v-for="item in options" :key="item.id" :label="item.name"
                  @click="pIdChange(item, 0)">{{ item.name
                  }}</a-select-option>
              </a-select>
              <a-select v-model="oneId" placeholder="一级" style="width:120px" option-label-prop="label" allowClear>
                <a-select-option :value="item.id" v-for="item in firstList" :key="item.id" :label="item.name"
                  @click="pIdChange(item, 1)">{{ item.name
                  }}</a-select-option>
              </a-select>
              <a-select v-model="twoId" placeholder="二级" style="width:120px" option-label-prop="label" allowClear>
                <a-select-option :value="item.id" v-for="item in secondList" :key="item.id" :label="item.name"
                  @click="pIdChange(item, 2)">{{ item.name
                  }}</a-select-option>
              </a-select>
              <a-select v-model="threeId" placeholder="三级" style="width:120px" option-label-prop="label" allowClear>
                <a-select-option :value="item.id" v-for="item in tertiaryList" :key="item.id" :label="item.name"
                  @click="pIdChange(item, 3)">{{ item.name }}</a-select-option>
              </a-select>
            </div>
            <span class="btn-box" style="margin-left: 10px;display: inline-block;">
              <a-button style="margin-left: 8px" @click="resetChange">重置</a-button>
              <a-button type="primary" @click="searchChange">查询</a-button>
            </span>
          </a-row>



        </div>

      </a-form>


    </div>

    <div class="select-list" v-if="hasPermission('GOODSAUTARKY_LIST')">
      <ul style="margin-bottom: 8px;">
        <li v-for="(item, index) in goodsType" :class="typeCurrent == index ? 'active-sel' : ''" :key="item.id"
          @click="filterTypeChange(index)">
          <img v-if="typeCurrent == index" :src="item.selIcon" alt="">
          <img v-else :src="item.icon" alt="">
          {{ item.name }}
        </li>
      </ul>
      <!-- <ul>
        <li v-for="(item, index) in orderType" :class="ordCurrent == index ? 'active-sel' : ''" :key="item.id"
          @click="filterOrderChange(index)">
          <img v-if="ordCurrent == index" :src="item.selIcon" alt="">
          <img v-else :src="item.icon" alt="">
          {{ item.name }}
        </li>
      </ul> -->
    </div>

    <div style="height: 77vh;display:flex;border: 1px solid #F3F3F3;border-bottom: 0;width: 100%;"
      v-if="hasPermission('GOODSAUTARKY_LIST')">


      <!-- 产品池 -->
      <div class="items-right">
        <div class="items-title">产品列表</div>
        <div>
          <ul v-if="prodList.length > 0">
            <li v-for="(item, index) in prodList" :key="index">
              <div class="items-tag" v-if="item.type == 2">礼包</div>
              <p style="text-align: center;" class="rig-img-box">
                <img :src="item.imagePath" style="object-fit: cover;width: 100%;height: 100%;" alt="">
              </p>
              <div class="prog-ls-top items-ls-top">
                <p class="ls-top-tit text-wrap1">{{ item.name }}</p>
                <p style="height:30px;display: flex;align-items:center;">
                  <a @click="openH(item.goodsSpecVoList[item.idx].thirdUrl)" target="_blank"
                    v-if="item.goodsSpecVoList[item.idx].thirdUrl">H</a>
                  <a-popover>
                    <template slot="content">
                      7天无理由退换货
                    </template>
                    <a style="cursor: default;" href="javascript:;"
                      v-if="item.goodsSpecVoList[item.idx].returnable == 1">7</a>
                  </a-popover>

                  <a-popover>
                    <template slot="content">
                      有质检报告
                    </template>
                    <a style="cursor: default;" href="javascript:;"
                      v-if="item.goodsSpecVoList[item.idx].isQirPic == 1">质</a>
                  </a-popover>
                </p>
              </div>
              <div class="prog-ls-btm" style="padding-top: 0;">
                <p>
                  <span>供应商 ID：</span>{{ item.goodsSpecVoList[item.idx].supplierId }}
                </p>
                <p>
                  <span>电商售价：</span>¥{{ item.goodsSpecVoList[item.idx].marketPrice }} <span
                    v-if="item.goodsSpecVoList[item.idx].threeTypeName">({{
      item.goodsSpecVoList[item.idx].threeTypeName
    }})</span>
                </p>
                <p>
                  <span>市场建议零售价：</span>¥{{ item.goodsSpecVoList[item.idx].plfPrice }}
                </p>
                <p>
                  <span>优加底价：</span>¥{{ item.goodsSpecVoList[item.idx].price }}
                </p>
              </div>
              <div style="height:60px;overflow-y: auto;font-size: 12px;padding:4px;">
                <div v-if="item.goodsSpecVoList.length > 0">
                  <!-- <a-radio-group :default-value="item.goodsSpecVoList[item.idx].goodsSpecId">
                    <p v-for="(it, i) in item.goodsSpecVoList" :key="it.goodsSpecId" style="padding:0 4px;">
                      <a-radio @change="info => goodsSpecChange(info, index, i)" :value="it.goodsSpecId">
                        <span v-if="item.status == 2" class="goods-tips"
                          style="border-radius:4px;position: relative;padding:2px;">下架</span>
                        <span>{{ it.specsAttributeValue }}</span>
                        <span style="margin-left:10px;">库存：{{ it.stock }}</span>
                      </a-radio>
                    </p>
                  </a-radio-group> -->
                  <div v-for="(it, i) in item.goodsSpecVoList" :key="it.goodsSpecId"
                    style="padding:0 4px;font-size: 13px;cursor: pointer;border-top: 1px solid #eee;padding:4px 0;"
                    :class="{ 'selText': item.idx == i }" @click="goodsSpecChange(index, i)">
                    <span v-if="it.status == 2" class="goods-tips"
                      style="border-radius:4px;position: relative;padding:2px;color:red;">下架</span>
                    <a-icon type="check" v-if="item.idx == i" />
                    <span>{{ it.specsAttributeValue }}</span>
                    <span style="margin-left:10px;">库存：{{ it.stock }}</span>
                  </div>
                </div>

              </div>

            </li>


          </ul>
          <div v-else class="items-empty">
            <a-empty :image="simpleImage" />
          </div>
          <!-- <ul v-if="prodList.length > 0">
            <li v-for="(item, index) in prodList" :key="index">
              <div class="items-tag" v-if="item.type == 2">礼包</div>
              <p style="text-align: center;" class="rig-img-box">
                <img :src="item.imagePath" style="object-fit: cover;width: 100%;height: 100%;" alt="">
              </p>
              <div class="prog-ls-top items-ls-top">
                <p class="ls-top-tit text-wrap1" style="height: 22px;">{{ item.packName }}</p>
                <p class="ls-top-tit text-wrap1">{{ item.name }}</p>
                <p>
                  <a @click="openH(item.thirdUrl)" v-if="item.isThirdUrl == 1 && item.thirdUrl">H</a>
                  <a href="javascript:;" v-if="item.isThirdUrl == 1 && !item.thirdUrl">H</a>
                  <a href="javascript:;" v-if="item.returnable == 1">7</a>
                  <a href="javascript:;" v-if="item.isQirPic == 1">质</a>
                </p>
              </div>
              <div class="prog-ls-btm" style="padding-top: 0;">
                <p>
                  <span>供应商 ID：</span>{{ item.supplierId }}
                </p>
               
                <p>
                  <span>优加底价：</span>¥{{ item.price }}
                </p>
              </div>
            </li>
          </ul> -->


          <div class="page-box">
            <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
              @change="handlePageChange" />
          </div>
        </div>



      </div>



    </div>
    <div v-if="!hasPermission('GOODSAUTARKY_LIST')" style="text-align: center;font-size: 20px;margin-top: 100px;">
      <a-empty :description="false" />
      无权限查看
    </div>
  </a-card>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import { request } from '@/utils/request'
import { Empty } from 'ant-design-vue';
export default {
  name: 'QueryList',
  data() {
    return {
      iptmd: 4,
      iptsm: 22,
      iptSpan: 5,
      form: this.$form.createForm(this),
      searchParams: {
        "page": 0,
        "size": 14,
      },
      queryData: {
        "page": 0,
        "size": 14
      },
      currentPage: 1,

      goodsType: [{ name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' }, { name: '单品', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon4.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon04.png' }, { name: '礼包', id: 2, icon: 'https://qiniu.youjiamall.cn/tupianxs.png', selIcon: 'https://qiniu.youjiamall.cn/tupianxs1.png' }],
      // orderType: [{ name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' }, { name: '热销', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon2.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon02.png' }, { name: '新品', id: 2, icon: 'https://qiniu.youjiamall.cn/xsicon1.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon01.png' }],
      ordCurrent: 0,
      typeCurrent: 0,


      advanced: true,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 12,
        total: 0
      },
      total: 0,
      current: 1,
      value: ['china'],

      // 新建方案
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,

      // 新建方案弹窗
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      prodList: [],

      progList: [],

      // 分类
      menuId: [],
      oneId: [],
      twoId: [],
      threeId: [],
      //当前选中的规格是第几条，清空勾选使用
      options: [
        {
          name: '商城',
          id: 1,
        },
        {
          name: '蛋糕',
          id: 3,
        },
        {
          name: '礼包',
          id: 4,
        },
        {
          name: '生活',
          id: 5,
        },
        {
          name: '演出',
          id: 7,
        },
        {
          name: '图书',
          id: 9,
        },
      ],
      firstList: [],
      secondList: [],
      tertiaryList: []

    }
  },
  authorize: {
    deleteRecord: 'delete'
  },

  mounted() {
    this.getData();//查询列表
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    changeCategory(e) {
      console.log(e, 'e')
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + e + '/' + 0 + '/' + 20220601001, 'get').then(res => {
        this.firstList = res.data.data;
      })
    },
    pIdChange(item, type) {
      console.log(item, type)
      if (type == 0) {
        this.oneId = []
        this.twoId = []
        this.threeId = []
        this.firstList = []
        this.secondList = []
        this.tertiaryList = []
      }
      if (type == 1) {
        this.twoId = []
        this.threeId = []
        this.secondList = []
        this.tertiaryList = []
      }
      if (type == 2) {
        this.threeId = []
        this.tertiaryList = []
      }
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/1' + '/' + item.id + '/20220601001', 'get').then(res => {
        if (type == 1) {
          this.secondList = res.data.data;
        }
        if (type == 2) {
          this.tertiaryList = res.data.data;
        }
      })
    },
    // 切换规格查看信息
    goodsSpecChange(index, i) {
      this.prodList[index].idx = i;
      this.$set(this.prodList, index, { ...this.prodList[index], idx: i });
    },
    openH(url) {
      window.open(url)
      // window.location.href = url
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    selectChange(checkedList) {
      this.delGroupIdList = checkedList;
    },

    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },



    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    // 筛选
    filterTypeChange(idx) {
      this.$message.loading();
      if (idx == 0) {
        this.queryData.type = ''
      } else {
        this.queryData.type = this.goodsType[idx].id
      }
      this.getData();
      this.typeCurrent = idx;
    },
    filterOrderChange(idx) {
      this.$message.loading();
      if (idx == 0) {
        this.queryData.order = ''
      } else {
        this.queryData.order = this.orderType[idx].id
      }
      this.getData();
      this.ordCurrent = idx;

    },



    // 查询
    searchChange() {
      this.queryData.classifyIdList = this.threeId
      this.getData();
      // if (this.searchParams.supplierId) {
      //   this.searchParams.supplierId = this.searchParams.supplierId - 10000
      // }
      // this.$message.loading();
      // request(process.env.VUE_APP_API_BASE_URL + 'sfa/sfaGoods/list', 'post', this.searchParams).then(res => {
      //   this.$message.destroy()
      //   // if (this.searchParams.supplierId) {
      //   //   this.searchParams.supplierId = this.searchParams.supplierId - 0 + 10000
      //   // }
      //   let data = res.data.data;
      //   if (res.data.code == 200) {
      //     this.prodList = data.data;
      //     this.prodList.forEach(item => {
      //       item.idx = 0;
      //       if (item.type == 2) {
      //         item.checkList = []
      //         if (item.goodsSpecVoList.length > 0) {
      //           item.goodsSpecVoList.forEach(it => {
      //             item.checkList.push(it.goodsSpecId)
      //           })
      //         }
      //       }
      //     })
      //     this.queryData.page = data.page;
      //     this.queryData.size = data.size;
      //     this.current = data.page + 1;
      //     this.total = data.total;
      //   }
      // })
    },
    resetChange() {
      this.queryData = {
        "page": 0,
        "size": 12,
        type: ""
      }
      this.ordCurrent = 0;
      this.typeCurrent = 0;
      this.menuId = []
      this.oneId = []
      this.twoId = []
      this.threeId = []
      this.firstList = []
      this.secondList = []
      this.tertiaryList = []
      this.ordCurrent = 0;
      this.typeCurrent = 0;
      this.firstClassifyId = ""
      this.secondClassifyId = ""
      delete this.queryData.returnable
      this.searchChange()
    },
    handleOk(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },

    getData() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/sfaGoods/list', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.$message.destroy()
        if (res.data.code == 200) {
          this.prodList = data.data;
          this.prodList.forEach(item => {
            item.idx = 0;
            if (item.type == 2) {
              item.checkList = []
              if (item.goodsSpecVoList.length > 0) {
                item.goodsSpecVoList.forEach(it => {
                  item.checkList.push(it.goodsSpecId)
                })
              }
            }
          })
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.search input {
  padding-left: 10px;
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}


::v-deep .page-content {
  padding: 0 !important;
}

.page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.left-top {
  position: relative;
  padding: 0 0px 20px;
  box-sizing: border-box;
  width: 20%;
  border-right: 1px solid #f3f3f3;
  overflow-y: scroll;
}


.items-right {
  width: 100%;
  padding: 0 27px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.items-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #010101;
  margin: 10px 0;
}

.items-list {
  list-style: none;
  padding: 10px;
  box-sizing: border-box;
  // height: 232px;
  background: #E8ECF9;
  border-radius: 8px;
}

.items-list li {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}


.items-list span {
  color: #5542F6;
}

.select-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 30px;
  box-sizing: border-box;
}

.select-nav>p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-nav img {
  width: 10px;
  height: 14px;
  margin-left: 6px;
  vertical-align: middle;
}

.select-nav p {
  cursor: pointer;
}

.select-nav p {
  width: 20%;
  text-align: center;
  background: #F7F9FA;
  border-radius: 20px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #93989A;
  padding: 6px 0px;
}

.prog-tit p {
  margin-right: 12px;
}

.prog-tit {
  margin-top: 10px;
  width: 100%;
  height: 46px;
  background: rgba(98, 113, 235, .15);
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #010101;
  justify-content: space-between;
  margin-bottom: 8px;
}

.prog-tit div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5542F6;
}

.prog-list {
  padding: 0 15px;
  box-sizing: border-box;
  min-height: 150px;
}

.prog-list li {
  border-radius: 8px;
  border: 1px solid #EBEAED;
  margin-bottom: 8px;
}

.prog-ls-top {
  position: relative;
  padding: 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EBEAED;
}

.prog-ls-top a {
  display: inline-block;
  width: 24px;
  background: #E8ECF9;
  height: 24px;
  margin-right: 6px;
  text-align: center;
}

.prog-ls-top img {
  margin-right: 12px;
  width: 55px;
  height: 55px;
  border-radius: 8px;
  border: 1px solid #EBEAED;
}

.ls-top-tit {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0D0A19;
  text-align: left;
  width: 90%;
}

.prog-ls-btm {
  color: #0D0A19;
  padding: 10px 12px;
  box-sizing: border-box;
}

.prog-ls-btm p {
  margin-bottom: 4px;
}

.prog-ls-btm span {
  color: #84818A;
}


.prog-btm {
  position: fixed;
  z-index: 9;
  bottom: 0;
  height: 50px;
  background: #5542F6;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.prog-btm button {
  width: 35%;
  height: 36px;
  border-radius: 8px;
}


.tems-right {
  padding: 10px 12px;
  box-sizing: border-box;
}

.items-right ul {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
}

.items-right li {
  position: relative;
  width: 13%;
  margin-right: 1%;
  /* 计算每个项的宽度 */
  // margin-right: 26px;
  border-radius: 14px;
  border: 1px solid #E4E6E8;
  padding: 10px 0;
  margin-bottom: 10px;
}

::v-deep .ant-card-body {
  padding: 12px !important;
  min-width: 1600px;
  overflow-x: auto;
}

// .ant-card-body {
//     padding: 12px !important;
//     zoom: .1 !important;
//   }
// @media (max-width: 1800px) {
//   ::v-deep .ant-card-body {
//     padding: 12px !important;
//     zoom: .8 !important;
//   }

//   ::v-deep .ant-col-md-4 {
//     width: 20% !important;
//     margin-right: 20px;
//   }

//   // /* 当前页面宽度小于1500px时应用的样式 */
//   .items-right {
//     width: 80%;
//   }

//   .items-right li {
//     width: 29%;
//     margin-right: 26px;
//     border-radius: 14px;
//     border: 1px solid #E4E6E8;
//     padding: 10px 0;
//     margin-bottom: 12px;
//   }


//   .left-top {
//     position: relative;
//     padding: 0 0px 20px;
//     box-sizing: border-box;
//     width: 35%;
//     border-right: 1px solid #f3f3f3;
//     overflow-y: scroll;
//   }
// }

.items-right .rig-img-box {
  margin: auto;
  width: 128px;
  height: 112px;
}

::v-deep .ant-card-bordered {
  border: none !important;
}

.ant-card-bordered {
  border: none !important;
}

.items-ls-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  border: none !important;
}

.items-btn {
  display: flex;
}

.items-btn button {
  width: 45%;
  height: 36px;
  margin-right: 10px;
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  border-radius: 8px;
}

.items-btn button:nth-child(1) {
  border: 1px solid #5542F6;
  color: #5542F6;
}

.items-btn button:nth-child(2) {
  margin-right: 0;
}

.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 6px;
}

.items-empty {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.ant-checkbox-group {
  width: 100%;
}



.select-list {
  padding: 4px 0;
  border-top: 1px solid #F3F3F3;
}

.select-list img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.select-list ul {
  display: flex;
}

.select-list li {
  cursor: pointer;
  width: 83px;
  height: 36px;
  border-radius: 8px;
  background: #F7F9FA;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #93989A;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.select-list .active-sel {
  background: #5542F6;
  color: #fff;
}



.text-wrap1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-wrap2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 控制显示的行数 */
  overflow: hidden;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
}





.items-tag {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 20px;
  background: #E8ECF9;
  border-radius: 0px 14px 0px 10px;
  border: 1px solid #5542F6;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #5542F6;
}


@media (max-width: 1800px) {
  .items-right li {
    width: 18%;
  }
}

@media (max-width: 1730px) {
  .items-right li {
    width: 16%;
  }
}

@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 20% !important;
  }

  .page-box {
    justify-content: left;
  }
}


.selText {
  color:#5542f6;
}
</style>
